<template>
  <div>
    <div class="row">
      <div class="col-8 offset-2">
        <div class="text-center">
          <img src="/imagenes/ico/salida.svg"  style="width: 80px;" />
          <p class="mt-3">¿Desea salir de la aplicación?</p>
          <a href="javascript:;" class="btn btn-outline-primary me-2" @click="volver">Continuar en la Aplicación</a>
          <a href="javascript:;" class="btn btn-outline-secondary" @click="salir">Si, Salir </a>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

  import {  mapActions } from 'vuex'

  export default {
    methods: {
      ...mapActions['almacenardatosusuario'],
      salir() {

          localStorage.clear();
          localStorage.removeItem('token')
          window.location="/";
          
        },
        volver() {
          window.location="/Escritorio";
        },
    },
  };
</script>